import React from 'react'
import "../css/Body.css"
import { Link } from 'react-router-dom'

const why = [
    {
        id: 1,
        icon: 'images/i3.png',
        name: 'Hardware Supply',
        text: 'Whether a single Item, bulk, we supply quality and affordable Items. Visit us to have your own experience'
    },
    {
        id: 2,
        icon: 'images/i2.png',
        name: 'Construction Services',
        text: 'Optimize your business processes with our business analysis services.'
    },
    {
        id: 3,
        icon: 'images/i4.png',
        name: 'Consultation Services',
        text: 'Our expert team specializes in creating robust and secure computer networks tailored to your needs'
    }
]

const works = [
    {
        id:1,
        img: "images/img1.jpeg",
        caption: "description of this image"
    },
    {
        id:2,
        img: "images/img3.jpeg",
        caption: "description of this image"
    },
    {
        id:3,
        img: "images/img6.jpeg",
        caption: "description of this image"
    }
]

const Body = () => {
  return (
    <>
       <div className='body'>
            <div class="main_heading text-center">
                <h2>Why Choose Us</h2>
                <p class="large">Fastest services with best price!</p>
            </div>
            <div className='row centered'>
                {why.map(item => (
                    <div key={item.id} className='col-lg-4 col-md-6 col-sm-6 col-xs-12 text-center'>
                        <div class="center">
                            <div class="icon"> <img src={item.icon} alt="#" /> </div>
                        </div>
                        <h4 class="theme_color">{item.name}</h4>
                        <p>{item.text}</p>
                    </div>
                ))}
            </div>
            <div class="row centered pt-4">
                <div class="col-md-8">
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <span class="accordion-header fw-bolder">
                            <button class="accordion-button fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Hardware Supply
                            </button>
                            </span>
                            <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <p>Our team of seasoned professionals specializes in providing strategic IT support, personalized advisory services, and insightful consulting to address your unique business needs. Whether you require ongoing technical assistance, strategic planning, or guidance on implementing cutting-edge technologies, we're here to assist.</p>
                            </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <span class="accordion-header">
                            <button class="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Construction Services
                            </button>
                            </span>
                            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <p>Maintaining the efficiency and reliability of your technological infrastructure is crucial. Our dedicated team specializes in proactive maintenance strategies designed to prevent system failures, optimize performance, and ensure seamless operations.</p>
                                <i className='text-danger'>For inquiries or to schedule a preventive maintenance service, please don't hesitate to contact us. We're committed to delivering top-tier ICT maintenance tailored to your unique needs.</i>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="full">
                        <h3 className='text-muted'>Need Our Services?</h3>
                        <p>We are committed to delivering excellence in all that we do. Contact us today to discover so much more. </p>
                        <p><Link to={'/services'} class="btn btn-lg btn-rounded rounded-pill ps-4 pe-4 btn-danger">View More</Link></p>
                    </div>
                </div>
            </div>
            <div class="centered bg-light mt-4">
                <div class="main_heading text-center">
                    <h2>Some of Our Works</h2>
                    <p class="large">Construction and Architectural</p>
                </div>
                <div className='plans'>
                    {works.map(item=>(
                        <figure key={item.id}>
                            <img src={item.img} alt='im' />
                            <figcaption>{item.caption}</figcaption>
                        </figure>
                    ))}
                </div>
                <p>Our company specializes in providing comprehensive construction services tailored to meet the diverse needs of our clients. From residential projects to commercial developments, we've successfully undertaken and completed a wide array of construction ventures. Our skilled workforce and state-of-the-art equipment ensure the seamless execution of projects, whether it's constructing modern homes, renovating commercial spaces, or overseeing large-scale infrastructure projects. With a commitment to quality craftsmanship and adherence to timelines, we've earned a reputation for delivering excellence in every aspect of construction.</p>
                <p>In addition to our construction services, we take immense pride in offering a comprehensive range of top-quality hardware supplies. Our extensive inventory includes a diverse selection of construction materials, tools, and equipment sourced from trusted manufacturers. Whether you require high-grade lumber, premium-grade cement, specialized tools, or cutting-edge machinery, we have you covered. Our team is dedicated to providing tailored solutions, ensuring that our clients receive the right products that meet their project specifications, all while maintaining competitive pricing and excellent customer service.</p>
                <div className='text-center p-4'>
                    <button className='btn btn-outline-danger btn-lg rounded rounded-pill'>Need a quote</button>
                </div>
            </div>
        </div>
    </>
  )
}

export default Body