import React from 'react'
import Navigator from './Navigator'
import Footer from './Footer'
import Top from './Top'
import Banner from './Banner'
import "../css/About.css"
const About = () => {
    const items = [
        "Ironsheets",
        "Wheelbarrows",
        "Hoes", 
        "Spades", 
        "pick axe", 
        "axe",
        "Trowel",
        "Tape measure",
        "Reinforcements of all sizes",
        "Timber",
        "Cement",
        "Nails"]
    const construction = [
        "Building plans",
        "Land Survey"
    ]
  return (
    <div>
        <Top />
        <Navigator />
        <Banner page={'About Us'}/>
        <div class="main_heading text-center">
            <h2>WE ARE A LEADING IT COMPANY</h2>
            <p class="large">Fastest services with best price!</p>
        </div>
        <div class="row about_blog">
            <div class="col-lg-6 col-md-6 col-sm-12 about_cont_blog">
                <div class="full text_align_left">
                <h3>What we do</h3>
                <p>Welcome to Nimble Associates Ltd, your one-stop
                    shopping center for all building requirements. Explore our comprehensive range of products 
                    designed to meet the diverse needs of individuals and businesses</p>
                <p>Some of our products include: </p>
                <ul style={{listStyle: 'none'}}>
                    <li><i class="fa fa-check-circle"></i>Hardware Supply of</li>
                    <li>
                        {items.map((item, index) => (
                            <label key={index}>{item}</label>
                        ))}
                    </li>
                    <li><i class="fa fa-check-circle"></i>Construction Services</li>
                    <li>
                        {construction.map((item, index) => (
                            <label key={index}>{item}</label>
                        ))}
                    </li>
                </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="full text_align_center"> <img class="img-fluid" src="images/about.avif" alt="#" /> </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default About