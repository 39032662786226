import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import "../css/Navigator.css"
import { useActivePage } from './ActivePageContext'

const Navigator = () => {
    const { activePage, setActivePage } = useActivePage();
    const [isVisible, setIsVisible] = useState(false);
    const handlePageChange = (page) => {
        setActivePage(page);
    };
    const handleMenu = () => {
        if (isVisible) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }
    }
    return (
        <div className='nav-menu d-flex w-100 justify-content-between align-items-center'>
            <img className='' src='/images/logo.png' />
            <h1 className='fs-5 fw-bold text-muted d-none'>Nimble Associates LTD</h1>
            <Link className='menu' onClick={handleMenu}><i class="fa-solid fa-bars"></i> Menu</Link>
            <nav className={isVisible ? 'd-block' :''}>
                <Link to={'/'} onClick={() => handlePageChange('home')} className={activePage === 'home' ? 'activePage' : ''}>Home</Link>
                <Link to={'/about'} onClick={() => handlePageChange('about')} className={activePage === 'about' ? 'activePage' : ''}>About Us</Link>
                <Link to={'/services'} onClick={() => handlePageChange('services')} className={activePage === 'services' ? 'activePage' : ''}>Services</Link>
                <Link to={'/contact'} onClick={() => handlePageChange('contact')} className={activePage === 'contact' ? 'activePage' : ''}>Contact Us</Link>
            </nav>
        </div>
    )
}

export default Navigator