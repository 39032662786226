import React from 'react'
import Navigator from './Navigator'
import Slidder from './Slidder'
import Body from './Body'
import Footer from './Footer'
import Top from './Top'

const Entry = () => {
  return (
    <div>
        <Top />
        <Navigator />
        <Slidder />
        <Body />
        <Footer />
    </div>
  )
}

export default Entry