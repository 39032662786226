import React from 'react'
import Navigator from './Navigator'
import Footer from './Footer'
import Top from './Top'
import Banner from './Banner'
import "../css/Services.css"

const services = [
    {
        id:1,
        name: 'Hardware Supply',
        text: 'Welcome to our one-stop destination for all your construction needs, offering a diverse selection of top-quality hardware supplies curated to enhance efficiency and durability in every project. Step into a world of construction possibilities with our extensive range of premium hardware supplies, meticulously sourced and designed to meet the demands of modern building ventures.',
        image: 'images/building.avif'
    },
    {
        id:2,
        name: 'Construction Services',
        text: 'Discover the essence of construction excellence with our extensive collection of reliable and innovative hardware supplies, designed to elevate your building projects.Step into a world of construction possibilities with our top-tier hardware supply range, crafted to empower your projects with durability, precision, and innovation.',
        image: 'images/construction.avif'
    },
    {
        id:2,
        name: 'Consultation Services',
        text: 'Explore a realm of comprehensive consultation services, where expertise meets personalized solutions, guiding you towards your goals with precision and insight. Experience tailored consultation services crafted to address your unique needs, providing expert guidance and strategic solutions for your endeavors.',
        image: 'images/consultation.avif'
    }
]

const Services = () => {
  return (
    <div>
        <Top />
        <Navigator />
        <Banner page={'Services'}/>
        <div class="main_heading text-center">
            <h2>Explore Our Services</h2>
            <p class="large">Fastest & Affordable Services!</p>
        </div>
        <div class="row services">
            {services.map(item => (
                <div key={item.id} class="col-md-4 service_blog">
                    <div class="service_img"> <img class="w-100" src={item.image} alt="#" /> </div>
                    <div class="service_cont">
                        <h4 class="service_head">{item.name}</h4>
                        <p>{item.text}</p>
                    </div>
                </div>
            ))}
        </div>
        <Footer />
    </div>
  )
}

export default Services