import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import "../css/Slidder.css"

const CarouselImage = ({img}) => {
    return(
        <>
            <img className='w-100' src={img} />
        </>
    )
}

const Slidder = () => {
  return (
    <div className='slidder'>
        <Carousel fade>
            <Carousel.Item interval={5000}>
                <CarouselImage img="https://img.freepik.com/free-photo/three-factory-workers-safety-hats-discussing-manufacture-plan_1303-30650.jpg?size=626&ext=jpg&ga=GA1.1.1983136723.1698148772&semt=sph" />
                <Carousel.Caption>
                <h3>Providing Quality & Affordable Building Equipment</h3>
                <p>Delivering Excellence in all we do .</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={5000}>
                <CarouselImage img="https://img.freepik.com/free-photo/african-american-worker-standing-uniform-wearing-safety-hat-factory_1303-30612.jpg?size=626&ext=jpg&ga=GA1.2.1983136723.1698148772&semt=sph" />
                <Carousel.Caption>
                <h3>Construction Services Provision</h3>
                <p>Precision, Innovation, and Dedication converge to shape your vision into reality</p>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    </div>
  )
}

export default Slidder