import React from 'react'
import Navigator from './Navigator'
import Footer from './Footer'
import Top from './Top'
import Banner from './Banner'
import "../css/Contact.css"
const Contact = () => {
  return (
    <div>
        <Top />
        <Navigator />
        <Banner page={'Contact Us'}/>
        <div class="main_heading text-center">
          <h2>We are a call away</h2>
          <p class="large">Always ready to serve you no matter what!</p>
        </div>
        <div className='row adress_cont m-auto'>
          <div class="col-md-6">
            <h4>Contact Information</h4>
            <p>Welcome to our platform! We strive to provide you with the best experience possible. Our team is dedicated to delivering high-quality services tailored to meet your needs.</p>
            <div class="d-flex align-items-center gap-4">
              <i class="fa fa-road" aria-hidden="true"></i>
              <div className='w-100'>
                <h4>Kyalwologoma, Kira Municipality</h4>
                <p>P.O. Box 20018, Nakawa</p>
                <p>Kampala (U)</p>
                <hr />
              </div>
            </div>
            <div class="d-flex align-items-center gap-4">
              <i class="fa fa-user" aria-hidden="true"></i>
              <div className='w-100'>
                <h4>+256-708-762761</h4>
                <p>Mon-Fri 8:30am-6:30pm</p>
                <hr/>
              </div>
            </div>
            <div class="d-flex align-items-center gap-4">
              <i class="fa fa-envelope" aria-hidden="true"></i>
              <div className='w-100'>
                <h4>info@nimbleassociates.com</h4>
                <p>24/7 online support</p>
                <hr />
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <iframe width="100%" height="400" frameborder="0" style={{border:0}} src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBGY-aKP5KQbjJbf2B2mN6u9909uI5iv50&amp;q= Kyalwologoma, Kira Municipality" allowfullscreen=""></iframe>
          </div>
        </div>
        <Footer />
    </div>
  )
}

export default Contact